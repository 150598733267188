import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSlug } from 'src/utils';
import { List } from './list';
import { GetPostCategoriesQuery } from 'graphql-types';

type Props = {
  className?: string;
};

type QueryProps = GetPostCategoriesQuery;

export const query = graphql`
  query GetPostCategories {
    prismic {
      japanese: allPost_categorys(lang: "ja-jp", sortBy: date_DESC) {
        ...PostCategories
      }
      english: allPost_categorys(lang: "en-us", sortBy: date_DESC) {
        ...PostCategories
      }
    }
  }
`;

function useCategories({
  langKey,
  japanese,
  english,
}: {
  langKey: string;
  japanese: GetPostCategoriesQuery['prismic']['japanese'];
  english: GetPostCategoriesQuery['prismic']['english'];
}) {
  return React.useMemo(() => (langKey === 'ja' ? japanese : english), [
    langKey,
    japanese,
    english,
  ]);
}

export const Categories: React.FC<Props> = ({ className }: Props) => {
  const { langKey } = useSlug();
  const {
    prismic: { japanese, english },
  } = useStaticQuery<QueryProps>(query);
  const categories = useCategories({ langKey, japanese, english });

  return (
    <div className={className || ''}>
      {categories && <List categories={categories} />}
    </div>
  );
};

export default Categories;
