import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Entry } from './entry';
import { PostEntriesFragment } from 'graphql-types';

type Props = {
  className?: string;
  entries: PostEntriesFragment;
};

export const fragment = graphql`
  fragment PostEntries on PRISMIC_PostConnectionConnection {
    edges {
      node {
        _meta {
          id
        }
        ...PostArchiveEntry
      }
    }
  }
`;

export const Entries: React.FC<Props> = ({
  className,
  entries: { edges },
}: Props) => (
  <div className={className || ''}>
    {edges &&
      edges.map(
        (edge) =>
          edge?.node && <StyledEntry key={edge.node._meta.id} {...edge.node} />
      )}
  </div>
);

const StyledEntry = styled(Entry)`
  & + & {
    margin-top: ${({ theme }) => theme.structure.margin.large}px;
  }
`;

export default Entries;
