import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Anchor, Img } from 'src/components/common/atoms';
import { PostArchiveEntryFragment } from 'graphql-types';

// NOTE:
// dayjs safari bug fix
// prismicのdayjsのformatでバグ
// https://github.com/iamkun/dayjs/issues/73#issuecomment-464443622
dayjs.extend(customParseFormat);

type Props = PostArchiveEntryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment PostArchiveEntry on PRISMIC_Post {
    _meta {
      uid
    }
    title
    date
    category {
      ... on PRISMIC_Post_category {
        title
      }
    }
    thumbnail_image
    thumbnail_imageSharp {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export const Entry: React.FC<Props> = ({
  className,
  _meta,
  title,
  date,
  category,
  thumbnail_image,
  thumbnail_imageSharp,
}: Props) => {
  const titleText = title ? RichText.asText(title) : '';
  return (
    <Wrapper className={className || ''}>
      <StyledAnchor to={_meta.uid ? `/news/${_meta.uid}/` : ''}>
        <Text>
          <Title>{titleText}</Title>
          <Meta>
            {category?.title && (
              <Category>{RichText.asText(category.title)}:</Category>
            )}
            {date && (
              <Time dateTime={date}>
                {dayjs(date, 'YYYY-MM-DDTHH:mm:ss+ZZ').format('YYYY.MM.DD')}
              </Time>
            )}
          </Meta>
        </Text>
        <Thumbnail className={!thumbnail_imageSharp ? 'empty' : ''}>
          {thumbnail_imageSharp?.childImageSharp?.fluid && (
            <Img
              fluid={thumbnail_imageSharp.childImageSharp.fluid}
              alt={thumbnail_image.alt || titleText || ''}
            />
          )}
        </Thumbnail>
      </StyledAnchor>
    </Wrapper>
  );
};

const Thumbnail = styled.figure`
  &.empty {
    border: 2px solid black;
  }
  >div {
    height: 100%;
  }
  .gatsby-image-wrapper {
    height: 100%;
    > div:first-of-type {
      padding-bottom: 0 !important;
    }
  }
  ${media.lessThanIpadHorizontal`
    flex: 0 0 24%;
  `}
  ${media.ipadHorizontalOrMore`
    flex: 0 0 31%;
  `}
  ${media.iphoneVerticalOrLess`
    flex: 0 0 31%;
  `}
`;

const Category = styled.span`
  text-transform: uppercase;
`;

const Time = styled.time`
  margin-left: 0.25em;
`;

const Meta = styled.div`
  font-family: ${({ theme }) => theme.font.family.helvetica};
  line-height: 1;
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.font.size.xlarge};
  line-height: 1.4;
  margin-top: 0.7em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: calc(3em * 1.3);
`;

const Text = styled.div`
  display: flex;
  flex-direction: column-reverse;
  text-align: left;
  ${media.lessThanIpadHorizontal`
    flex: 0 0 72%;
  `}
  ${media.ipadHorizontalOrMore`
    flex: 0 0 61%;
  `}
  ${media.iphoneVerticalOrLess`
    flex: 0 0 61%;
  `}
`;

const StyledAnchor = styled(Anchor)`
  display: flex;
  flex-direction: row-reverse;
  text-decoration: none;
  justify-content: space-between;
  &:hover,
  &.focus-visible {
    ${Title} {
      text-decoration: underline;
    }
  }
  &:focus-visible {
    ${Title} {
      text-decoration: underline;
    }
  }
`;

const Wrapper = styled.article`
  font-weight: 600;
`;

export default Entry;
