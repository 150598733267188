import * as React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { media } from 'src/styles';
import { Anchor as CommonAnchor } from 'src/components/common/atoms';
import { Anchor } from './anchor';
import { PostCategoriesFragment } from 'graphql-types';

type Props = {
  className?: string;
  categories: PostCategoriesFragment;
};

export const fragment = graphql`
  fragment PostCategories on PRISMIC_Post_categoryConnectionConnection {
    edges {
      node {
        ...PostCategory
      }
    }
  }
`;

export const List: React.FC<Props> = ({
  className,
  categories: { edges },
}: Props) => (
  <Wrapper className={className || ''}>
    <ListItem>
      <StyledCommonAnchor to={'/news/'}>All</StyledCommonAnchor>
    </ListItem>
    {edges?.map((edge, index) => (
      <ListItem key={`post-category-${index}`}>
        {edge?.node && <StyledAnchor {...edge.node} />}
      </ListItem>
    ))}
  </Wrapper>
);

const AnchorBaseStyle = css`
  text-decoration: none;

  &[aria-current],
  &:hover,
  &.focus-visible {
    text-decoration: underline;
  }
  &:focus-visible {
    text-decoration: underline;
  }
`;

const StyledCommonAnchor = styled(CommonAnchor)`
  ${AnchorBaseStyle};
`;

const StyledAnchor = styled(Anchor)`
  ${AnchorBaseStyle};
`;

const ListItem = styled.li`
  font-size: ${({ theme }) => theme.font.size.xxlarge};
  font-weight: 600;
  text-transform: uppercase;
  ${media.ipadHorizontalOrMore`
    & + & {
      margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
    }
  `}
`;

const Wrapper = styled.ul`
  line-height: 1;
  ${media.lessThanIpadHorizontal`
    display: flex;
    justify-content: space-between;
  `}
`;

export default List;
