import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { media } from 'src/styles';
import { useIsMobile } from 'src/utils';
import { ContentColumn, PageHeader } from 'src/components/common';
import { Entry } from './entry';
import { Entries } from './entries';
import { Categories } from './categories';
import { PostContentFragment } from 'graphql-types';

type Props = PostContentFragment & {
  className?: string;
  isSingle: boolean;
};

export const fragment = graphql`
  fragment PostContent on PRISMIC {
    post(lang: $lang, uid: $postUid) {
      ...PostSingleEntry
    }
    posts: allPosts(
      lang: $lang
      sortBy: date_DESC
      where: { category: $cateogryId }
    ) {
      ...PostEntries
    }
  }
`;

function useItems({
  isSingle,
  post,
  posts,
}: {
  isSingle: boolean;
  post: Props['post'];
  posts: Props['posts'];
}) {
  const isMobile = useIsMobile();
  return React.useMemo(() => {
    if (isMobile) {
      if (isSingle) {
        return [
          {
            component: <Entry {...post} isSingle={isSingle} />,
          },
        ];
      } else {
        return [
          {
            component: (
              <PageHeader title={'News'} isSingle={isSingle}>
                <Categories />
              </PageHeader>
            ),
          },
          {
            component: <StyledMobileEntries entries={posts} />,
          },
        ];
      }
    } else {
      if (isSingle) {
        return [
          {
            component: <Entry {...post} isSingle={isSingle} />,
            order: 3,
          },
          {
            component: <Entries entries={posts} />,
            order: 2,
          },
          {
            component: (
              <PageHeader title={'News'} isSingle={isSingle}>
                <Categories />
              </PageHeader>
            ),
            order: 1,
          },
        ];
      } else {
        return [
          {
            component: (
              <PageHeader title={'News'} isSingle={isSingle}>
                <Categories />
              </PageHeader>
            ),
            order: 1,
          },
          {
            component: <Entries entries={posts} />,
            order: 2,
          },
          {
            component: <Entry {...post} isSingle={isSingle} />,
            order: 3,
          },
        ];
      }
    }
  }, [isMobile, isSingle, post, posts]);
}

export const Content: React.FC<Props> = ({
  className,
  isSingle,
  post,
  posts,
}: Props) => {
  const items = useItems({ isSingle, post, posts });
  return (
    <Wrapper className={className || ''}>
      {post && <ContentColumn items={items} />}
    </Wrapper>
  );
};

const StyledMobileEntries = styled(Entries)`
  ${media.lessThanIpadHorizontal`
    margin-top: ${({ theme }) => theme.structure.margin.largeWithText}px;
  `}
`;

const Wrapper = styled.div`
  ${media.lessThanIpadHorizontal`
    /* NOTE:
    flexで伸ばした場合、子のheight100%が反映されないので、以下を指定。
    例えば、スマホのnewsの詳細ページの記事本文が短い場合。
    http://localhost:8888/news/210321_event/
    news側でも同様のスタイルを当てる。
    layouts/index.tsx
    common/content-column/content-column.tsx
  */
    flex: 1;
    display: flex;
    flex-direction: column;
  `}
`;

export default Content;
