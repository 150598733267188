import * as React from 'react';
import { graphql } from 'gatsby';
import { SliceContent } from 'src/components/common';
import {
  PostSingleContentTextFragment,
  PostSingleContentImageFragment,
} from 'graphql-types';

type Props = {
  className?: string;
  content: (PostSingleContentTextFragment | PostSingleContentImageFragment)[];
};

export const textFragment = graphql`
  fragment PostSingleContentText on PRISMIC_PostBodyText {
    type
    primary {
      text
    }
  }
`;

export const imageFragment = graphql`
  fragment PostSingleContentImage on PRISMIC_PostBodyImage_gallery {
    type
    fields {
      gallery_image
      gallery_imageSharp {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export const Content: React.FC<Props> = ({ className, content }: Props) => (
  <SliceContent className={className || ''} content={content} />
);

export default Content;
