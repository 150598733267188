import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { useSlug, useTranslationLink } from 'src/utils';
import { SEO } from 'src/components/common';
import { Content } from 'src/components/post';
import { GetPostQuery } from 'graphql-types';
import { PostPageContextProps, LangKeyType } from 'custom-types';

type Props = PageProps<GetPostQuery, PostPageContextProps>;

export const query = graphql`
  query GetPost($lang: String!, $postUid: String!, $cateogryId: String) {
    site {
      siteMetadata {
        titleCustom {
          ja {
            news
          }
          en {
            news
          }
        }
        description {
          ja {
            news
          }
          en {
            news
          }
        }
      }
    }
    prismic {
      post(lang: $lang, uid: $postUid) {
        _meta {
          alternateLanguages {
            uid
            type
          }
        }
        title
        meta_description
        body {
          ... on PRISMIC_PostBodyText {
            ...PostSingleContentText
          }
        }
        thumbnail_image
        thumbnail_imageSharp {
          publicURL
        }
      }
      ...PostContent
    }
  }
`;

type UseSEOProps = {
  site: GetPostQuery['site'];
  post: GetPostQuery['prismic']['post'];
  isSingle: boolean;
  langKey: LangKeyType;
};
function useSEO({ site, post, isSingle, langKey }: UseSEOProps) {
  return React.useMemo(() => {
    let title: string | null;
    if (isSingle) {
      title = post?.title ? RichText.asText(post?.title) : null;
    } else {
      if (langKey === 'ja') {
        title = site?.siteMetadata?.titleCustom?.ja?.news || null;
      } else {
        title = site?.siteMetadata?.titleCustom?.en?.news || null;
      }
    }
    let description: string | null;
    if (isSingle) {
      description = post?.meta_description
        ? RichText.asText(post?.meta_description)
        : null;
    } else {
      if (langKey === 'ja') {
        description = site?.siteMetadata?.description?.ja?.news || null;
      } else {
        description = site?.siteMetadata?.description?.en?.news || null;
      }
    }
    const image = post?.thumbnail_imageSharp?.publicURL || undefined;
    return {
      title,
      description,
      image,
    };
  }, [site, post, isSingle, langKey]);
}

const Post: React.FC<Props> = ({
  data: { site, prismic },
  pageContext: { isSingle },
}: Props) => {
  const { slug, langKey } = useSlug();
  const seo = useSEO({
    site,
    post: prismic?.post,
    isSingle,
    langKey,
  });

  useTranslationLink({
    slug,
    langKey,
    uid: isSingle ? prismic.post?._meta?.alternateLanguages?.[0]?.uid : 'news',
    type: isSingle
      ? prismic.post?._meta?.alternateLanguages?.[0]?.type
      : 'post-archive',
  });

  return (
    <>
      <SEO {...seo} />
      <Content {...prismic} isSingle={isSingle} />
    </>
  );
};

export default Post;
