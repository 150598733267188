import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Content } from './content';
import { PostSingleEntryFragment } from 'graphql-types';

// NOTE:
// dayjs safari bug fix
// prismicのdayjsのformatでバグ
// https://github.com/iamkun/dayjs/issues/73#issuecomment-464443622
dayjs.extend(customParseFormat);

type Props = PostSingleEntryFragment & {
  className?: string;
  isSingle: boolean;
};

export const fragment = graphql`
  fragment PostSingleEntry on PRISMIC_Post {
    title
    date
    category {
      ... on PRISMIC_Post_category {
        title
      }
    }
    body {
      ... on PRISMIC_PostBodyText {
        ...PostSingleContentText
      }
      ... on PRISMIC_PostBodyImage_gallery {
        ...PostSingleContentImage
      }
    }
  }
`;

export const Entry: React.FC<Props> = ({
  className,
  isSingle,
  title,
  date,
  category,
  body,
}: Props) => (
  <Wrapper className={className || ''} as={isSingle ? 'div' : 'article'}>
    <Header as={isSingle ? 'div' : 'header'}>
      {title && (
        <Title as={isSingle ? 'h1' : 'h2'}>{RichText.asText(title)}</Title>
      )}
      <Meta>
        {category?.title && (
          <Category>{RichText.asText(category.title)}:</Category>
        )}
        {date && (
          <Time dateTime={date}>
            {dayjs(date, 'YYYY-MM-DDTHH:mm:ss+ZZ').format('YYYY.MM.DD')}
          </Time>
        )}
      </Meta>
    </Header>
    {body && <StyledContent content={body} />}
  </Wrapper>
);

const Category = styled.span`
  text-transform: uppercase;
`;

const Time = styled.time`
  margin-left: 0.25em;
`;

const Meta = styled.div`
  font-family: ${({ theme }) => theme.font.family.helvetica};
  line-height: 1;
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xlarge};
  line-height: 1.4;
  margin-top: ${({ theme }) => theme.structure.margin.xSmallWithLargeText}px;
  text-align: left;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column-reverse;
`;

const StyledContent = styled(Content)`
  margin-top: ${({ theme }) => theme.structure.margin.mediumWithLargeText}px;
  ${media.ipadHorizontalOrMore`
    margin-top: ${({ theme }) => theme.structure.margin.medium}px;
  `}
`;

const Wrapper = styled.article``;

export default Entry;
