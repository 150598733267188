import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { useSlug } from 'src/utils';
import { Anchor as CommonAnchor } from 'src/components/common/atoms';
import { PostCategoryFragment } from 'graphql-types';

type Props = PostCategoryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment PostCategory on PRISMIC_Post_category {
    _meta {
      uid
    }
    title
  }
`;

export const Anchor: React.FC<Props> = ({ className, _meta, title }: Props) => {
  const { langKey } = useSlug();
  const uid = _meta?.uid || '';
  const to = langKey === 'ja' ? `/news/${uid}/` : `/${langKey}/news/${uid}/`;
  return (
    <CommonAnchor className={className || ''} to={to}>
      {title && RichText.asText(title)}
    </CommonAnchor>
  );
};

export default Anchor;
